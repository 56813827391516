<template>
  <div class="cpi-application-list-alert d-flex">
    <div class="py-4 px-2">
      <v-icon color="warning" dense>
        fal fa-comment-alt-lines
      </v-icon>
    </div>
    <div class="pa-4">
      <div class="h6 tier-title">
        {{ $t(`cpiApplicationListAlert.tier.${application.tier}.title`) }}
      </div>
      <div class="subtitle-1 aon-gray-01--text">
        {{ $t(`cpiApplicationListAlert.tier.${application.tier}.message`) }}
      </div>
    </div>
    <div class="pa-4 ml-auto align-self-center">
      <AppButton
        class="mr-2 archive-button"
        :loading="loading"
        @click="archiveApplication"
      >
        {{ $t(`cpiApplicationListAlert.archive`) }}
      </AppButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CpiApplicationList',
  props: {
    application: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async archiveApplication() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          'cpiApplications/archiveApplication',
          this.application.id
        );
      } catch (error) {
        this.handleGenericApiError();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.cpi-application-list-alert {
  white-space: normal;

  .archive-button {
    height: 40px;
    font-size: 0.875rem;
    min-height: 0;

    &:not(:hover) {
      color: $aon-peacock !important;
      border-color: $aon-peacock !important;
      background-color: white !important;
    }
    &:hover {
      background-color: $aon-peacock !important;
      border-color: $aon-peacock !important;
    }
  }

  .tier-title {
    font-size: 0.875rem;
  }
}
</style>
